<template>
  <div class="deal-container">

    <div v-if="isAreaType && isSplitDealType">

      <b-tabs @input="tabInput">
        <b-tab v-for="(dealItemList, index1) in landingDealBySubList" :key="index1"
          :active="index1 === currentTypeIndex">
          <template #title>
            {{ dealItemList.name }}
          </template>
          <b-card-text>
            <LesiurtabContent :dealListItem="dealItemList" />
          </b-card-text>
        </b-tab>

        <b-tab v-for="(dealItemList, index2) in landingDealForOrg" :key="landingDealBySubList.length + index2"
          :active="(landingDealBySubList.length + index2) === currentTypeIndex">
          <template #title>
            {{ dealItemList.name }}
          </template>
          <b-card-text>
            <LesiurtabContent :dealListItem="dealItemList" />
          </b-card-text>
        </b-tab>

      </b-tabs>
    </div>
    <div class="container" v-else-if="isAreaType">
      <b-tabs @input="tabInput">
        <b-tab v-for="(item, index) in landingDealBySubList" :key="index" :active="index === preferedActiveDest">
          <template #title>
            {{ item.destName }}
          </template>
          <b-card-text>
            <b-tabs class="sub-tab-name" pills @input="subTabIndex">
              <b-tab v-for="(dealItem, index) in item.list" :key="index" :active="index === preferedActiveDealType">
                <template #title >
                  {{ dealItem.name }}
                </template>
                <b-card-text>
                  <LesiurtabContent :dealListItem="dealItem" />
                </b-card-text>
              </b-tab>
            </b-tabs>
          </b-card-text>
        </b-tab>

        <b-tab v-if="landingDealForOrg">
          <template #title>
            {{ landingDealForOrg.name }}
          </template>
          <b-card-text>
            <b-tabs class="sub-tab-name" pills @input="subTabIndex">
              <b-tab v-for="(dealItem, index) in landingDealForOrg.list" :key="index">
                <template #title >
                  {{ dealItem.destName }}
                </template>
                <b-card-text>
                  <LesiurtabContent :dealListItem="dealItem" />
                </b-card-text>
              </b-tab>
            </b-tabs>
          </b-card-text>
        </b-tab>

      </b-tabs>
    </div>
    <div class="container" v-else>
      <b-tabs @input="tabInput">
        <b-tab v-for="(dealItemList, index) in landingDealList" :key="index" :active="index === currentTypeIndex">
          <template #title>
            {{ dealItemList.name }}
          </template>
          <b-card-text>
            <LesiurtabContent :dealListItem="dealItemList" />
          </b-card-text>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BTabs, BTab, BCardText } from 'bootstrap-vue';
import gMixin from '@/utils/mixins';

export default {
  name: 'LandingDeal',
  components: {
    // LesiurPackageItem: () => import('@/components/home/LesiurpackageItem'),
    LesiurtabContent: () => import('@/components/home/lesiurtabContent/LesiurtabContentTheme0'),
    BTabs,
    BTab,
    BCardText,
  },
  mixins: [gMixin],
  model: {
    props: 'dealTypeCode',
    event: 'change',
  },
  props: {
    preferDestCode: String,
    preferDealType: String,
  },
  computed: {
    ...mapGetters({
      landingDealData: 'GET_LANDING_DEAL_DATA',
      landingInfo: 'GET_LANDING_INFO',
      dealType: 'GET_DEAL_TYPE',
      lang: 'GET_LANGUAGE',
      landingAreaData: 'GET_LANDING_AREA_DATA',
      // listDestinations: 'GET_LANDING_DESTINATIONS',
    }),
    isAreaType() {
      const { landingInfo } = this;
      if (!landingInfo) return false;
      return landingInfo.areaType || false;
    },
    filterDealTypeCodes() {
      return this.landingInfo.dealTypeCodes;
    },
    landingDealList() {
      if (!this.dealType || this.dealType.length === 0) return [];
      return this.makeLandingDealList(this.landingDealData, this.lang);
    },
    isSplitDealType() {
      const { landingAreaData } = this;
      return landingAreaData?.splitAllDealTypes ?? false;
    },
    landingDealBySubList() {
      const { isAreaType, landingDealData, landingAreaData, lang } = this;
      if (!isAreaType || !landingDealData || !landingAreaData || !this.dealType) return [];

      return this.makeLandingDealByArea(landingDealData, landingAreaData, lang);
    },
    landingDealForOrg() {
      const { isAreaType, landingDealData, landingAreaData, lang, isSplitDealType } = this;
      if (!isAreaType || !landingDealData || !landingAreaData || !this.dealType) return null;

      const dealListOrg = this.makeLandingDealList(landingDealData.filter((item) => (item.categoryId === 'Organize_tour_packages')), lang);
      if (dealListOrg.length === 0) return null;

      if (isSplitDealType) {
        // dealListOrg.forEach((orgDeal) => {
        //   const destName = orgDeal.list?.[0][1][0].destinationName?.[lang] || '';
        //   orgDeal.name = `${orgDeal.name} - ${destName}`;
        // });

        return dealListOrg;
      }
      return this.makeLandingDealOnlyORG(dealListOrg[0], landingAreaData, lang);
    },
    currentTypeIndex() {
      const temp = this.landingDealList.findIndex((item) => this.landingInfo.dealType.toLowerCase() === item.code.toLowerCase());
      return temp === -1 ? 1 : temp;
    },
    preferedActiveDest() {
      const index = this.landingDealBySubList.findIndex((item) => this.preferDestCode === item.destCode);
      return index === -1 ? 0 : index;
    },
    preferedActiveDealType() {
      if (this.landingDealBySubList.length === 0) return 0;
      const index = this.landingDealBySubList[0].list.findIndex((item) => this.preferDealType === item.code);
      return index === -1 ? 0 : index;
    },
  },
  data() {
    return {
    };
  },
  methods: {
    subTabIndex(inx) {
      this.$emit('change', this.landingDealList[inx]?.code || '');
      if (this.landingDealBySubList.length) this.$emit('changeDest', this.landingDealBySubList[0].destCode || '');
    },
    tabInput(inx) {
      if (this.isAreaType) {
        this.subTabIndex(0);
      } else {
        this.$emit('change', this.landingDealList[inx]?.code || '');
      }
    },
    makeLandingDealOnlyORG(dealListOrg, landingAreaData, lang) {
      const destList = landingAreaData.packCategDests.Organize_tour_packages;
      const dealList = [...dealListOrg.list];

      dealListOrg.list = [];

      destList.forEach((dest) => {
        const deals = dealList.filter((deal) => deal[1][0].destinationCode === dest);

        const destName = (deals.length && deals[0][1][0]?.destinationName[lang]) || '';

        if (destName) {
          dealListOrg.list.push({ destCode: dest, destName: destName || '', list: deals });
        }
      });
      return dealListOrg;
    },
    makeLandingDealByArea(landingDealData, landingAreaData, lang) {
      const tempArray = [];
      // const { filterDealTypeCodes } = this;

      const vacationList = landingAreaData.packCategDests.vacation_pack.map((dest) => {
        const filteredList = landingDealData.filter((item) => (item.destinationCode === dest && item.categoryId === 'vacation_pack'));
        const list = this.makeLandingDealList(filteredList, lang);
        return { destCode: dest, destName: filteredList[0]?.destinationName[lang] || '', list };
      });
      tempArray.push(...vacationList.filter((item) => item.list.length > 0));

      const foList = landingAreaData.packCategDests.Flight_Only.map((dest) => {
        const filteredList = landingDealData.filter((item) => (item.destinationCode === dest && item.categoryId === 'Flight_Only'));
        const list = this.makeLandingDealList(filteredList, lang);
        return { destCode: dest, destName: filteredList[0]?.destinationName[lang] || '', list };
      });

      tempArray.push(...foList.filter((item) => item.list.length > 0));

      const grouped = Object.entries(this.groupByKey(tempArray, 'destCode'));

      const resultArray = [];
      grouped.forEach((item) => {
        const destCode = item[0];
        const { destName } = item[1][0];
        const list = item[1].map((elem) => elem.list).reduce((p, c) => p.concat(c));

        if (this.isSplitDealType) {
          list.forEach((li) => {
            li.name = `${li.name}-${destName}`;
          });
          resultArray.push(...list);
        } else {
          resultArray.push({ destCode, destName, list });
        }
        // if (filterDealTypeCodes && filterDealTypeCodes.length) {
        //   // ?destination=Pesach&areaType=true&dealTypeCode=Pesach&dealTypeCode=WEEKEND

        //   const ftList = list.filter((li) => filterDealTypeCodes.includes(li.code.toLowerCase()));
        //   if (ftList.length) resultArray.push({ destCode, destName, list: ftList });
        // } else {
        //   // ?destination=Pesach&areaType=true

        //   resultArray.push({ destCode, destName, list });
        // }
      });

      return resultArray;
    },
    makeLandingDealList(data, lang) {
      const tempArray = this.dealType.data.filter((type) => type.forHomeOrLanding === 'landingpage' || type.forHomeOrLanding === 'both')
        .map((item) => ({ name: item.name[lang], code: item.code, list: [], categoryId: null }));
      tempArray.forEach((elm) => {
        /**
         * Filter the deals matched category and isSalesDeal is a false
         */
        const listFilteredByDealType = data.filter(
          (item) => (
            item.dealTypeCode === elm.code && !item.isSalesDeal
          ),
        );
        elm.categoryId = listFilteredByDealType[0]?.categoryId || null;

        /**
         * Filter the deals matched category and deal that isSalesDeal is true
         */
        const salesDeals = data.filter(
          (item) => (
            item.dealTypeCode === elm.code && item.isSalesDeal
          ),
        );
        const autoDealGroup = [], salesDealGroup = [];
        if (elm.code === 'Flight_Only' || elm.code === 'Organize_tour_packages') {
          autoDealGroup.push(...Object.entries(this.groupByKey(listFilteredByDealType, 'id')));
          if (salesDeals.length > 0) salesDealGroup.push(...salesDeals.map((item) => ([String(item.id), [item]])));
        } else if (elm.code !== 'Family Org') {
          autoDealGroup.push(...Object.entries(this.groupByKey(listFilteredByDealType, 'hotelId')));
          if (salesDeals.length > 0) salesDealGroup.push(...Object.entries(this.groupByKey(salesDeals, 'hotelId')));
        } else {
          autoDealGroup.push(...Object.entries(this.groupByKey(listFilteredByDealType, 'id')));
        }

        elm.list = autoDealGroup.filter((autoDealItem) => {
          const idx = salesDealGroup.map((salesDealItem) => salesDealItem[0]).indexOf(autoDealItem[0]);
          if (idx > -1) {
            salesDealGroup[idx][1].push(...autoDealItem[1]);
          }
          return idx === -1;
        });
        elm.list.push(...salesDealGroup);
      });

      tempArray.forEach((item) => {
        item.list.sort((a, b) => {
          const priceA = a[1][0].packageType === 'F' ? a[1][0].discountedPriceFO.priceByAge[0].price : a[1][0].discountedPrice.price_average,
            priceB = b[1][0].packageType === 'F' ? b[1][0].discountedPriceFO.priceByAge[0].price : b[1][0].discountedPrice.price_average;
          let result = 0;
          if (a[1][0].isSalesDeal) {
            if (!b[1][0].isSalesDeal) {
              result = -1;
            } else {
              result = priceA < priceB ? -1 : 1;
            }
          } else {
            result = (!b[1][0].isSalesDeal && priceA < priceB) ? -1 : 1;
          }
          return result;
        });
      });

      return tempArray.filter((item) => (item.list.length > 0));
    },
  },
  mounted() {
    // console.log(this.preferDestCode, this.preferDealType, this.landingAreaData, this.landingInfo);
  },
};
</script>

<style scoped>
.deal-container{
  padding: 3rem;
}
@media (max-width: 767px) {
  .deal-container{
    padding: 0;
  }
}
</style>
<style>
.deal-container ul.nav.nav-tabs {
  border: none;
  margin-bottom: 30px;
}
.deal-container ul.nav.nav-tabs li:first-child {
  border-right: none;
}
.deal-container ul.nav.nav-tabs li {
  padding: 0 0;
  margin: 0 0;
  position: relative;
  border-right: 1px solid #bbb;
  padding-left: 10px;
  text-transform: capitalize;
}
.deal-container ul.nav.nav-tabs li a.active {
  background: #005fa8;
  color: #fff;
}
.deal-container ul.nav.nav-tabs li a:hover {
  background: #005fa8;
  color: #fff;
}
.deal-container ul.nav.nav-tabs li:first-child a {
  margin: 0 0;
}
.deal-container ul.nav.nav-tabs li a {
  border: none;
  padding: 8px 18px;
  margin: 0 10px 0 0;
  background: transparent;
  border-radius: 5px;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  position: relative;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
}
.deal-container ul.nav.nav-tabs li a.active:after {
  display: block;
  background: #005fa8;
}
.deal-container ul.nav.nav-tabs li a:after {
  display: none;
  content: " ";
  height: 8px;
  width: 8px;
  position: absolute;
  background-color: #005fa8;
  left: -4px;
  bottom: -4px;
  margin: 0 50%;
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  -webkit-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}
.deal-container .tab-content {
  border: none;
}
.deal-container .tabs.sub-tab-name ul.nav.nav-pills li a{
  padding: 5px 15px;
  color: black;
  font-size: 18px;
  font-weight: 600;
}
.deal-container .tabs.sub-tab-name ul.nav.nav-pills li a.active {
  background: #005fa8;
  color: #fff;
}
ul.nav {
  padding-right: 0;
}
@media (max-width: 479px) {
  .lesiurpackages_head h1 {
    font-size: 18px;
  }
  .lesiurpackages_head {
    min-height: auto;
    padding: 20px 20px;
  }
  .deal-container ul.nav.nav-tabs li a {
    padding: 6px 8px;
    font-size: 14px;
    margin-right: 5px;
  }
  .deal-container ul.nav.nav-tabs {
    padding-right: 0px;
    /* flex-wrap: nowrap; */
  }
  .lesiurpackages_hotelsbox.big {
    height: 300px;
  }
  .deal-container ul.nav.nav-tabs li {
    padding-left: 5px;
  }
}
</style>
